import Link from "next/link"
import { useRouter } from "next/router"

import { Logo } from "ui/logo"

import styles from "./not-found.module.scss"

function NotFound({ logo, logoAlt, photographerName }) {
  const router = useRouter()

  function onClick() {
    router.back()
  }

  return (
    <div className={styles["root"]}>
      <header className={styles["header"]}>
        <Logo logo={logo} logoAlt={logoAlt} photographerName={photographerName} align="center"></Logo>
      </header>
      <main className={styles["content"]}>
        <h1 className={styles["title"]}>Ошибка 404</h1>
        <p className={styles["description"]}>Страница не найдена. Проверьте адрес ссылки.</p>
        <Link href="/">
          <a className={styles["go-back"]} onClick={onClick}>
            Вернуться назад
          </a>
        </Link>
      </main>
    </div>
  )
}
export { NotFound }
