import React from "react"

import { Meta } from "@app/features/meta/components"
import { fetchGeneralSettings } from "@app/features/general-settings/requests"
import { getMeta } from "@app/features/meta/utils"
import { NOT_FOUND_PAGE_META_KEY } from "@app/features/meta/constants"

import { NotFound } from "@app/ui/pages/not-found"
import { REQUEST_TYPE } from "@app/api/constants"

function NotFoundPage() {
  const [settings, setSettings] = React.useState(null)

  React.useEffect(() => {
    async function fetchSettings() {
      const settings = await fetchGeneralSettings({ requestType: REQUEST_TYPE.CLIENT, domain: location.host })

      setSettings(settings)
    }

    fetchSettings()
  }, [setSettings])

  return (
    <React.Fragment>
      <Meta {...getMeta({ key: NOT_FOUND_PAGE_META_KEY })}></Meta>
      <NotFound logo={settings?.logo} logoAlt={settings?.logoAlt} photographerName={settings?.name}></NotFound>
    </React.Fragment>
  )
}

export default NotFoundPage
